<ng-container *ngIf="p && p[type]">

    <ng-container *ngIf="p[type]">
        <div *ngIf="loaded" class="loading-container">
            <div class="spinner"></div>
        </div>
        <div *ngIf="!loaded && p[type] && ((mdp && verified) || (!mdp)) " class="full-page"
            [style.background]="backgroundStyle">
            <div class="top-page">
                <div class="profil">
                    <div class="photo">
                        <img *ngIf="p[type].photo" [src]="p[type].photo" alt="image">
                        <img *ngIf="!p[type].photo" src="assets/images/logo2.svg" alt="image">
                    </div>
                    <h2>{{p.social.prenom}} {{p.social.nom}} </h2>
                    <h3>{{p.social.profession}}</h3>
                    <h4 style="padding: 20px;text-align: center;">{{p[type].bio}}</h4>
                    <div class="buttons-container">
                        <button data-toggle="modal" data-target="#staticBackdrop" style="width:100px;"
                            [style.background]="btnStyle" (click)="openExchange()">Exchange</button>
                    </div>
                    <!-- <div class="contact">
                        <div class="contact-icon">
                            
                                <img src="assets/images/tel.svg"  />
                            </svg>
                        </div>
                        <div class="contact-icon">
                            
                                <img src="assets/images/sms.svg"  />
                            </svg>
                        </div>
                        <div class="contact-icon">
                            
                                <img src="assets/images/adresse.svg"  />
                            </svg>
                        </div>
                    </div> -->
                </div>

            </div>

            <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body ">
                            <div class="text-right"> <i class="fa fa-close close" data-dismiss="modal"><span
                                        class="glyphicon glyphicon-remove"></span></i> </div>

                            <div class="container mt-5 mb-5 d-flex justify-content-center">
                                <div class="card px-1 py-4">
                                    <div *ngIf="exSuccess == null" class="card-body" style="color: black;">
                                        <h2 class="card-title mb-3">
                                            Echangez avec <span
                                                style="font-weight: bold; font-family: Arial, Helvetica, sans-serif;">{{
                                                p[type].nom }}</span>
                                        </h2>
                                        <form
                                            (submit)="submitForm($event, prenom.value, nom.value, entreprise.value, mail.value, tel.value, msg.value)">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <input #prenom class="form-control" type="text"
                                                            placeholder="Prenom *" required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <input #nom class="form-control" type="text" placeholder="Nom *"
                                                            required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <input #entreprise class="form-control" type="text"
                                                            placeholder="Entreprise">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <input #mail class="form-control" type="email"
                                                            placeholder="Mail *" required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <input #tel class="form-control" type="tel" placeholder="Tel *"
                                                            required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <textarea style="height: 100px;" #msg class="form-control"
                                                            type="text-area" placeholder="Message" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column text-center px-5 mt-3 mb-3">
                                                <p>{{submitted}} En soumettant, j'accepte que mes données soient
                                                    transmises à l'entreprise et utilisées à des fins professionnelles.
                                                </p>
                                                <div *ngIf="!submitted" class="buttons-container">
                                                    <button type="submit" [style.background]="btnStyle">
                                                        <span class="glyphicon glyphicon-send" aria-hidden="true"
                                                            style="margin-right: 10px;"></span> Envoyer
                                                    </button>
                                                </div>
                                                <div *ngIf="submitted" style="display: flex; justify-content: center;">
                                                    <div class="spinner"></div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>

                                    <div *ngIf="exSuccess == true" class="card-body" style="    color: black;
                                    text-align: left;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;">
                                        <h2 class="card-title mb-3">
                                            Votre demande d'échange a été <span
                                            style="font-weight: bold; font-family: Arial, Helvetica, sans-serif;">transmise !</span> 
                                        </h2>
                        
                                    </div>

                                    <div *ngIf="exSuccess == false" class="card-body" style="    color: black;
                                    text-align: left;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;">
                                        <h2 class="card-title mb-3">
                                            Votre demande d'échange n'a pas été <span
                                            style="color:red;font-weight: bold; font-family: Arial, Helvetica, sans-serif;">transmise !</span> 
                                        </h2>
                        
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

            <div class="cwrap">
                <div class="contact" [style.background]="backgroundStyle">
                    <div class="contact-icon" *ngIf="p[type].mail">
                        <a [href]="mail">

                            <img src="assets/images/mailsms.svg" />
                        </a>

                    </div>
                    <div class="contact-icon" *ngIf="p[type].tel">
                        <a [href]="sms">
                            <img src="assets/images/tel.svg" />
                        </a>
                    </div>
                    <div class="contact-icon" *ngIf="p[type].adresse">
                        <a [href]="adresse">
                            <img src="assets/images/adresse.svg" /> 
                        </a>
                    </div>
                    <!-- <div class="contact-icon">
                            <img src="assets/images/paiement.svg" />
                        </div> -->
                </div>

            </div>
            <div class="dwrap">
                <div class="bottom-page">
                    <div>
                        <section id="social" style="border:none">
                            <div class="section-title">
                                <h3>Social Media</h3>
                            </div>
                            <div class="section-content-scroll">

                                <ng-container *ngFor="let soc of findRes()">


                                    <a *ngIf="soc.link && soc.active && soc.name !='whatsapp'" (click)="metrics(soc.name,soc.link)">
                                        <div class="card">
                                            <div class="card-header"></div>
                                            <div class="card-content">
                                                <img src="../../../assets/images/{{ soc.name }}.png" />
                                            </div>
                                        </div>
                                    </a>
                                    <a *ngIf="soc.link && soc.active && soc.name =='whatsapp'" [href]="whatsapp">
                                        <div class="card">
                                            <div class="card-header"></div>
                                            <div class="card-content">
                                                <img src="../../../assets/images/{{ soc.name }}.png" />
                                            </div>
                                        </div>
                                    </a>
                                </ng-container>
                            </div>

                        </section>


                    </div>
                </div>
                <div class="sticky-bottom">
                    <div class="buttons-container">
                        <button [style.background]="btnStyle" (click)="openApp()">Ajouter aux tags</button>
                        <!-- <button [vcdDownloadVCard]="vCard">Ajouter aux contacts</button> -->
                        <button [style.background]="btnStyle" (click)="downloadVcard()">Ajouter aux contacts</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>



    <section *ngIf="mdp && !verified" class="full-page" [style.background]="backgroundStyle"
        style="height: 50%;background: linear-gradient(180deg, #000 0%, #373b3c 99.99%, rgba(76, 165, 251, 0.00) 100%)">
        <div class="top-page">
            <div class="aninimated-title">
                <h2 class="text-uppercase heading-light ls-1 ah-headline">
                    <span style="font-size: 30px;">Profil protégé par un mot de passe !</span>

                    <!-- <span class="ah-words-wrapper">
                        <b class="is-visible">Plus de connexions</b>
                        <b>Plus de fluidité</b>
                        <b>HelloTag</b>
                    </span> -->
                </h2>
            </div>
        </div><!-- End Container -->
    </section><!-- End Section -->
    <!-- ==============================================
    **PROFILE**
    =============================================== -->
    <section *ngIf="mdp && !verified" class="bbs1 bc-light pb-60">
        <div class="container">
            <div class="home-profile" style="border-radius: 20px;">
                <div class="profile-card-two">
                    <header class="profile-card-header">
                        <!-- here’s the avatar -->
                        <a href="assets/images/#">
                            <img src="assets/images/logo2.svg" alt="image">
                        </a>
                        <h2>Déverouille le profil !</h2>
                        <h4>Tape le code à 4 chiffres !</h4>
                    </header>
                    <div class="profile-bio" style="margin: auto;display: table;">
                        <div *ngIf="!verified">
                            <form>
                                <br>
                                <input id="pw" style="border-radius: 6px;padding:10px;text-align: center;" type="number"
                                    placeholder="Mot de passe" #pw>
                                <div *ngIf="message != '' || message != null" style="margin: auto;
                                display: table;
                                padding: 10px;">
                                    <span style="color:red">{{message}}</span>
                                </div>
                            </form>
                            <button (click)="verif(pw)" class="jolie-btn">
                                Valider
                            </button>
                        </div>
                    </div>


                </div>
            </div>

        </div><!-- End Container -->
        <div *ngIf="loaded" class="loading">
            <div class="loader"></div>
        </div>
    </section>